<template>
  <div id="viewall-gifts">
    <Loader v-if="loading" />
    <VgiftsPanel v-else
                 :gifts="gifts"
                 :view_all="true"
                 :public_view="public_view"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import VgiftsPanel from "@/components/VgiftsPanel";
import Loader from "@/components/Loader";

export default {
  name: "ViewAllGifts",
  components: {
    VgiftsPanel,
    Loader
  },
  props: {
    public_view: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      gifts: []
    }
  },
  computed: {
    ...mapGetters(['giftsAllCache']),
  },
  methods: {
    ...mapMutations(['setGiftsAllCache'])
  },
  created() {
    if (this.giftsAllCache) {
      this.gifts = this.giftsAllCache;
      return;
    }

    this.loading = true;

    this.$ajax({
      type: 'get_gifts',
      params: {
        type: 'gifts',
        room_name: this.public_view ? this.$store.getters.roomProfile.short_info.nickname : null
      },
      then: (data) => {
        this.loading = false;
        this.gifts = data.gifts || [];
        this.setGiftsAllCache(this.gifts);
      },
      catch: (data) => {
        this.loading = false;
        console.log(data);
      },
    });
  }
}
</script>

<style scoped>
#viewall-gifts {
  min-height: 300px;
}
</style>

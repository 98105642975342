<template>
  <div id="vgift-panel-item-container" :class="{stack: is_stack, public: public_view}" @click="showGiftInfo">
    <div
      class="vgift-item"
      :class="{animated: isAnimated}"
    >
      <div v-if="xp > 0" class="vgift-item-xp">
        <span>+ {{ xp }} XP</span>
      </div>
      <img ref="vgift_img"
           :src="imageSrc"
           :alt="nameDecoded"
           :title="nameDecoded"
           :class="animation_class"
      >
      <div v-if="isNew && !public_view" class="status-new" />
      <div v-if="isRewardCategory" class="status-reward-category" />
    </div>
  </div>
</template>

<script>
export default {
  name: "VgiftPanelItem",
  props: ['gift_id', 'image_id', 'name', 'status', 'is_stack', 'xp', 'public_view'],
  data() {
    return {
      animation_class: '',
      custom_style_element: null
    }
  },
  computed: {
    imageSrc() {
      return `${this.$external.url.blob}${this.image_id}`;
    },
    nameDecoded() {
      return this.$helper.decode(this.name);
    },
    isAnimated() {
      return this.checkStatus('animated');
    },
    isNew() {
      return this.checkStatus('new');
    },
    isRewardCategory() {
      return this.checkStatus('reward_category');
    }
  },
  mounted() {
    if (this.isAnimated) {

      //todo: rewrite animation to transitions, not keyframe

      let style = document.createElement('style');
      style.appendChild(document.createTextNode(''));
      document.head.appendChild(style);
      this.custom_style_element = style.sheet;

      const width = this.$refs.vgift_img.clientWidth;
      const height = this.$refs.vgift_img.clientHeight;

      const steps = Math.round(width / height);
      const keyframes_selector = `animation-${height}-${steps}`;

      this.animation_class = `animate-${height}-${steps}`

      let rules = `animation: ${keyframes_selector} ${steps / 10}s infinite steps(${steps});`;
      rules += '-webkit-' + rules;

      this.addCssRule(`img.${this.animation_class}`, rules);

      rules = `to {left: -${height * steps}px;}`;

      this.addCssRule(`@keyframes ${keyframes_selector}`, rules);
      this.addCssRule(`@-webkit-keyframes ${keyframes_selector}`, rules);
    }
  },
  methods: {
    checkStatus(status) {
      let find = this.status.find((item) => {
        return item === status
      });
      return typeof find != 'undefined';
    },
    addCssRule(selector, rules, index) {
      if (this.custom_style_element === null) {
        return;
      }

      index = index || this.custom_style_element.cssRules.length;
      try {
        if (this.custom_style_element.insertRule) {
          this.custom_style_element.insertRule(selector + "{" + rules + "}", index);
        } else if (this.custom_style_element.addRule) {
          this.custom_style_element.addRule(selector, rules, index);
        }
      } catch(e) {
        console.log(e);
      }
    },
    showGiftInfo() {
      if (this.public_view) {
        return this.$helper.openURL(this.$external.url.base + `store/#gift=${this.gift_id}`);
      }
      this.$ajax({
        type: 'get_gift_info',
        params: {
          gift_id: this.gift_id,
        },
        then: (data) => {
          this.loading = false;
          this.$store.dispatch('openModal', {
            content: 'GiftInfo',
            options: {
              gift: this,
              stack_info: data.gift
            },
            close_button: true,
            result: '',
          });
        },
        catch: (data) => {
          this.loading = false;
          console.log(data);
        },
      });
    }
  }
}
</script>
<style scoped>
#vgift-panel-item-container {
  margin: 10px 5px 40px;
  white-space: normal;
  vertical-align: top;
  padding: 10px;
  position: relative;
}

#vgift-panel-item-container.stack {
  background-image: url(~@/assets/images/gift_stack.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.vgift-item {
  height: 100px;
  width: 100px;
  cursor: pointer;
  overflow: hidden;
}

.vgift-item-xp {
  position: absolute;
  height: 16px;
  width: 100%;
  top: -8px;
  left: 0;
  font-weight: 700;
  font-size: 11px;
  color: #fff;
  line-height: 16px;
  text-align: center;
}
.vgift-item-xp span {
  display: inline-block;
  height: 100%;
  padding: 0 11px;
  border-radius: 16px;
  background-color: #9a47ff;
}

.stack .vgift-item-xp span {
  background: #9a48ff; /* Old browsers */
  background: -moz-linear-gradient(312.72deg, #EF42FF -37.88%, #9433FF 149.42%, #9A48FF 149.42%);
  background: -webkit-linear-gradient(312.72deg, #EF42FF -37.88%, #9433FF 149.42%, #9A48FF 149.42%);
  background: linear-gradient(312.72deg, #EF42FF -37.88%, #9433FF 149.42%, #9A48FF 149.42%);
}

.status-new {
  position: absolute;
  width: 34px;
  height: 14px;
  top: 113px;
  left: 0;
  right: 0;
  margin: 0 auto;
  background-image: url(~@/assets/images/gift_new.svg);
  background-repeat: no-repeat;
}

.vgift-item.animated img {
  position: relative;
  height: 100%;
  left: 0;
}

.status-reward-category {
  position: absolute;
  height: 25px;
  width: 25px;
  top: 0;
  right: 0;
  background-repeat: no-repeat;
  background-image: url(~@/assets/images/icon_reward_points.svg);
  background-size: cover;
}
</style>

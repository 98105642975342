import { render, staticRenderFns } from "./VgiftPanelItem.vue?vue&type=template&id=008d1ec4&scoped=true&"
import script from "./VgiftPanelItem.vue?vue&type=script&lang=js&"
export * from "./VgiftPanelItem.vue?vue&type=script&lang=js&"
import style0 from "./VgiftPanelItem.vue?vue&type=style&index=0&id=008d1ec4&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "008d1ec4",
  null
  
)

export default component.exports
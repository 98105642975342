<template>
  <div id="vgift-panel-container" :class="{ 'view-all' : view_all, 'public' : public_view}">
    <VgiftPanelItem v-for="(gift, index) in gifts"
                    :key="index"
                    v-bind="gift"
                    :public_view="public_view"
    />
  </div>
</template>
<script>

import VgiftPanelItem from "@/components/VgiftPanelItem";

export default {
  name: "VgiftsPanel",
  components: {
    VgiftPanelItem
  },
  props: {
    gifts: {
      type: Array,
      required: true,
    },
    view_all: {
      type: Boolean,
      required: false,
      default: false
    },
    public_view: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {}
}
</script>

<style scoped>
#vgift-panel-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-bottom: 20px;
}
#vgift-panel-container.public,
#vgift-panel-container.view-all {
  flex-wrap: wrap;
  justify-content: center;
}
</style>
